// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2016-07-09-jest-index-md": () => import("./../../../src/pages/2016-07-09-jest/index.md" /* webpackChunkName: "component---src-pages-2016-07-09-jest-index-md" */),
  "component---src-pages-2019-07-27-create-react-app-index-md": () => import("./../../../src/pages/2019-07-27-create-react-app/index.md" /* webpackChunkName: "component---src-pages-2019-07-27-create-react-app-index-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

